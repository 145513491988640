import React from 'react';
import AllRoute from '../router'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { Container } from 'react-bootstrap';


const App = () => { 

  return (
    <Container className="App" id='scrool'>
          <AllRoute/>
          <ToastContainer/>
    </Container>
  );
}

export default App;
