import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/clinicSign.png'
import hero2 from '../../images/youngManHoldingShoe.webp'
import hero3 from '../../images/firstpatient.webp'
import hero4 from '../../images/shape/shape.png'

import './style.css'



class Hero extends Component {

    constructor(props){
        super(props);

        this.state = {
            myImages: [
                hero1,
                hero2,
                hero3
            ],
            showImage: 0
        }
    }

    nextImage = () => {
        setInterval(() => {
            if(this.state.showImage < 2) {
                this.setState({ showImage: this.state.showImage + 1 })
            } else {
                this.setState({showImage: 0});
            }
        }, 5000);
    }

    // prevImage = () => {
    //     if(this.state.showImage > 0) {
    //         this.setState({ showImage: this.state.showImage - 1 })
    //     } else {
    //         this.setState({showImage: 2});
    //     }
        
    // }

    componentDidMount() {
        this.nextImage()
    }

    render() {


        const divStyles = {
            border: '1px solid green',
            height: 'auto',
            width: '250px',
            borderRadius: '5px',
            textAlign: 'center',
            padding: '10px',
        }

        let showImage = this.state.showImage;

        return (

            <section className="" >
                <div style={{textAlign: 'center'}}>
                    <div style={{margin: '0 auto', width: '100%', background: '#fff'}}>
                        <img src={this.state.myImages[showImage]} alt=""  />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', margin: '25px 0'}}>
                        <div style={divStyles}>
                            <h6>Our Vision</h6>
                            <p>
                                Siloam Ministry aims to meet the physical, 
                                spiritual and emotional needs of people in 
                                need while providing an opportunity for 
                                them to experience the love of Jesus Christ personally.
                            </p>
                            <Link to="/mission" className="btn btn-success text-white">Read More</Link>
                            <a fl-giving-link fl-style href="https://faithlife.com/siloamethio/give"> Give </a>
                        </div>
                        <div style={divStyles}>
                            <h6>Our Goals</h6>
                            <ul>
                                <li> ✓ Create a free medical clinic in Harar, Ethiopia</li>
                                <li> ✓ Create satellite clinics in 12 rural schools.</li>
                                <li> ✓ Drill wells to provide clean drinking water for the villages</li>
                                <li> ✓ Build a state-of-the-art medical center</li>
                            </ul>
                            <Link to="/projects" className="btn btn-success">Learn More</Link>
                            <a fl-giving-link fl-style href="https://faithlife.com/siloamethio/give"> Give </a>
                        </div>
                        <div style={divStyles}>
                            <h6>Help us</h6>
                            <p>
                                Our 501(c)(3) charity currently supports a free medical 
                                clinic in Harar, Ethiopia. We are still looking for 
                                donations of medical equipment, specifically a 
                                CBC machine and blood chemistry supplies.
                            </p>
                            {/*<Link to="/donate" className="btn btn-success">Donate Now</Link>*/}
                            <a fl-giving-link fl-style href="https://faithlife.com/siloamethio/give"> Give </a>
                        </div>
                        <div style={divStyles}>
                            <h6>Get Involved</h6>
                            <ul>
                                <li> ❤ Sponsor 1 Full-Time Nurse: $200</li>
                                <li> ❤ Sponsor 1 child for: $50</li>
                                <li> ❤ Sponsor rent for the clinic: $300</li>
                                <li> ❤ Sponsor rent for the children: $275</li>
                                <li> ❤ Sponsor one well: $5500</li>
                            </ul>
                            <Link to="/getinvolved" className="btn btn-success">Get Involved</Link>
                            
                            <a fl-giving-link fl-style href="https://faithlife.com/siloamethio/give"> Give </a>
                        </div>
                    </div>
                </div>
                {/* <Slider {...settings}>
                    <div className="slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${hero1})`}}>
                            <div className="container">
                                <div className="slide-thumb">
                                    <span>GO FOR HELP</span>
                                </div>
                                <div className="slide-title">
                                    <h2>We need clinics</h2>
                                </div>
                                <div data-swiper-parallax="400" className="slide-text">
                                    <p>We have a lot os sick with no money for health care. ለጤና እንክብካቤ ገንዘብ የሌላቸው ብዙ የታመሙ ሰዎች አሉን።</p>
                                </div>
                                <div  className="slide-btns">
                                    <Link to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                </div>
                            </div>
                            <div className="slide-shape">
                                <img src={hero4} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${hero2})`}}>
                            <div className="container">
                                <div className="slide-thumb">
                                    <span>GO FOR HELP</span>
                                </div>
                                <div className="slide-title">
                                    <h2>We have fatherless kids on the streets</h2>
                                </div>
                                <div data-swiper-parallax="400" className="slide-text">
                                    <p>On the streets of Harar Ethiopia, we have fatherless kids in many numbers. በሐረር ኢትዮጵያ ጎዳናዎች ላይ አባት የሌላቸው ልጆች በብዛት አሉን!</p>
                                </div>
                                <div  className="slide-btns">
                                    <Link to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                </div>
                            </div>
                            <div className="slide-shape">
                               <img src={hero4} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${hero3})`}}>
                            <div className="container">
                                <div className="slide-thumb">
                                    <span>GO FOR HELP</span>
                                </div>
                                <div className="slide-title">
                                    <h2>They Are Wait For Some Food.</h2>
                                </div>
                                <div data-swiper-parallax="400" className="slide-text">
                                    <p>It is a long established fact that a reader will be distracted the readable content of a page when looking at its layout point of using of letters, as opposed.</p>
                                </div>
                                <div  className="slide-btns">
                                    <Link to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="slide-shape">
                               <img src={hero4} alt="" />
                            </div>
                        </div>
                    </div>
                </Slider> */}
            </section>
        )
    }
}

export default Hero;