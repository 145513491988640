import React from 'react';
import ContactForm from '../ContactFrom'
import './style.css'

const Contactpage = () => {

    return (
        <div style={{height: '70vh'}}>
            <h1 style={{textAlign: 'center', fontSize: '50px', margin: '30px auto'}}>CONTACT US </h1>
            <div class="card">
                <div class="card-body">
                    <h5><i className="ti-mobile"></i> Phone: (717) 874-8510</h5>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5><i className="ti-email"></i> Email: siloamethio@gmail.com</h5>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5><i className="ti-map"></i> Mailing address: 175 Church Street Landisville, PA 17538</h5>
                </div>
            </div>
        </div>
     )
        
}

export default Contactpage;
