import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Features from '../../components/Features'
import About2 from '../../components/about2'
import TeamSection from '../../components/team'
import Testimonial from '../../components/Testimonial'
import CounterSection from '../../components/counter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'

import SIGN from '../../images/OurImpact/SIGN.webp'
import Harar from '../../images/OurImpact/Harar.webp'
import IMPROVINGTHEAREA from '../../images/OurImpact/IMPROVINGTHEAREA.webp'
import HOPEFORNEWLIFE from '../../images/OurImpact/HOPEFORNEWLIFE.webp'
import CHILDRENSNEEDS from '../../images/OurImpact/CHILDRENSNEEDS.webp'
import WELLS from '../../images/OurImpact/WELLS.webp'

const OurImpact = (props) => {

    const impacts = [
        {
            "id": 1,
            "title": "LIFE IN HARAR",
            "paragraph": "Ethiopia is a tough place to live. Life expectancy is only 60 years. Getting clean water is nearly impossible. Extreme poverty is very high. Half of all children over the age of 5 must work to support their families.",
            "imageUrl": Harar
        },
        {
            "id": 2,
            "title": "PRIMARY HEALTHCARE",
            "paragraph": "Our medical team sees over 400 people a month. As well as a complete permanent building we have mobile clinics.",
            "imageUrl": SIGN
        },
        {
            "id": 3,
            "title": "HOPE FOR NEW LIFE",
            "paragraph": "This young lady was pregnant, malnourished and homeless. Although she was nearly 8 months along the baby wasn't growing well. We provided her with iron tablets and vitamins. She has already come back for her followup appointment. We wish her a safe delivery. Ethiopia is one of the 6 most dangerous countries to give birth in.",
            "imageUrl": HOPEFORNEWLIFE
        },
        {
            "id": 4,
            "title": "IMPROVING THE AREA",
            "paragraph": "Our medical clinic was once a vacant building. We had lots of local volunteers show up, eager to help us set up.",
            "imageUrl": IMPROVINGTHEAREA
        },
        {
            "id": 5,
            "title": "CHILDREN'S NEEDS",
            "paragraph": "One of 20 babies die in their first year in Ethiopia. That is why we prioritize pediatric care. We have a dedicated pediatric exam room and are raising funds for a pediatric nebulizer. Please consider donating today. ",
            "imageUrl": CHILDRENSNEEDS
        },
        {
            "id": 6,
            "title": "WELLS",
            "paragraph": "Water is scarce in Ethiopia. We are committed to providing clean water to the communities we serve because water is a cornerstone of health. Our first well is located at a school near the Harar clinic.",
            "imageUrl": WELLS
        }
    ]

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div>
            <h1 style={{fontSize: '70px', margin: '25px auto', textAlign: 'center'}}> OUR IMPACT </h1>
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', border: '0.5px solid', padding: '40px', margin: '25px auto'}}>
                {
                   impacts.map((impact) => {
                       return (
                            <div style={{width: '400px', height: 'auto'}}>
                                <img src={impact.imageUrl} alt={impact.paragraph} />
                                <h4>{impact.title}</h4>
                                <p>{impact.paragraph}</p>
                            </div>
                       )
                   })
                }
            </div>
        </div>
    )
}

export default OurImpact;