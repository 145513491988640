import React from 'react'
import {Link} from 'react-router-dom'
import causesimg from '../../images/wel.jpeg'
import causesimg2 from '../../images/drinkingWater.jpg'
import causesimg3 from '../../images/OurImpact/HOPEFORNEWLIFE.webp'
// import causesimg4 from '../../images/cause/img-4.jpg'
// import causesimg5 from '../../images/cause/img-5.jpg'
// import causesimg6 from '../../images/cause/img-6.jpg'
import './style.css'

const Causes = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="case-area section-padding">
            <div className="container">
                <div className="col-lg-6 offset-lg-3">
                    <div className="section-title section-title2 text-center">
                        <div className="thumb-text">
                            <span>CAUSES</span>
                        </div>
                        <h2>Latest Caused of SILOAM.</h2>
                        <p>It is a long established fact that reader distracted by the the readable content off page looking at its layout point.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg2} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">GOAL : $9860</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">RISED : $768</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/case-single">Build drinking water well for Children in schools with out drinking water source</Link></h3>
                                <p>Young children are more vulnerable to the effects of lead because they absorb ingested lead more easily than adults, which can interfere with the development of their nervous systems. In population studies, exposure to lead has been associated with effects on learning capacity, intellectual development and behavior.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">GOAL : $9860</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">RISED : $768</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/case-single">Build School Clinic</Link></h3>
                                <p><div>School clinic is a health care facility within school intended to provide basic health
                                    care for students and /or refer them to the specialist/primary health center if
                                    required.</div>
                                    <strong>School clinic shall comprise at least of the following:</strong>
                                    Doctor room/clinic should be of adequate size (at least 14 square meters) to
                                    accommodate standard furniture, fixtures, medical equipment and supplies with
                                    sufficient ventilation and lighting.
                                    Washbasin and taps water should be available in the clinic.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg3} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">GOAL : $9860</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">RISED : $768</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/case-single">Help the community medically</Link></h3>
                                <p>People simple mediacal issues are dying because they don't  afford to get even the simplest medical care they need. If clincs are opened that at least do the bare minimum it would have made it easier to help those who are very needy.</p>
                            </div>
                        </div>
                    </div>
                    {/*<div className="col-lg-4 col-md-6 col-12">*/}
                    {/*    <div className="cause-item">*/}
                    {/*        <div className="cause-top">*/}
                    {/*            <div className="cause-img">*/}
                    {/*                <img src={causesimg4} alt="" />*/}
                    {/*                <div className="case-btn">*/}
                    {/*                    <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="cause-text">*/}
                    {/*            <ul>*/}
                    {/*                <li><Link onClick={ClickHandler} to="/">GOAL : $9860</Link></li>*/}
                    {/*                <li><Link onClick={ClickHandler} to="/">RISED : $768</Link></li>*/}
                    {/*            </ul>*/}
                    {/*            <h3><Link onClick={ClickHandler} to="/case-single">Food And Home for Children</Link></h3>*/}
                    {/*            <p>It is a long established fact that a reader will be distracted.</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-4 col-md-6 col-12">*/}
                    {/*    <div className="cause-item">*/}
                    {/*        <div className="cause-top">*/}
                    {/*            <div className="cause-img">*/}
                    {/*                <img src={causesimg5} alt="" />*/}
                    {/*                <div className="case-btn">*/}
                    {/*                    <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="cause-text">*/}
                    {/*            <ul>*/}
                    {/*                <li><Link onClick={ClickHandler} to="/">GOAL : $9860</Link></li>*/}
                    {/*                <li><Link onClick={ClickHandler} to="/">RISED : $768</Link></li>*/}
                    {/*            </ul>*/}
                    {/*            <h3><Link onClick={ClickHandler} to="/case-single">Pure Water For The World</Link></h3>*/}
                    {/*            <p>It is a long established fact that a reader will be distracted.</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-4 col-md-6 col-12">*/}
                    {/*    <div className="cause-item">*/}
                    {/*        <div className="cause-top">*/}
                    {/*            <div className="cause-img">*/}
                    {/*                <img src={causesimg6} alt="" />*/}
                    {/*                <div className="case-btn">*/}
                    {/*                    <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="cause-text">*/}
                    {/*            <ul>*/}
                    {/*                <li><Link onClick={ClickHandler} to="/">GOAL : $9860</Link></li>*/}
                    {/*                <li><Link onClick={ClickHandler} to="/">RISED : $768</Link></li>*/}
                    {/*            </ul>*/}
                    {/*            <h3><Link onClick={ClickHandler} to="/case-single">Recycling For Charity</Link></h3>*/}
                    {/*            <p>It is a long established fact that a reader will be distracted.</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

export default Causes;