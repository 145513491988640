import React from 'react'
import {Link} from 'react-router-dom'
import VideoModal from '../../components/ModalVideo'
import abimg from '../../images/firstpatient.webp'
import groupPic from '../../images/Aboutus/Group.webp'
import abimg2 from './../../images/about/1.png'
import abimg3 from './../../images/about/2.png'
import abimg4 from './../../images/shape/shape2.png'
import {colors, createStyles} from "@material-ui/core";
import {red} from "@material-ui/core/colors";

import pastorAndWegayehu from '../../images/Aboutus/American_Board/PastorAndWegayehu.webp'
import lenor_hut from '../../images/Aboutus/American_Board/lenor_hut.webp'
import john_lehman from '../../images/Aboutus/American_Board/john_lehman.webp'
import karen_burrows from '../../images/Aboutus/American_Board/karen_burrows.webp'
import Lindsey_Teske from '../../images/Aboutus/American_Board/Lindsey_Teske.webp'
import etagegnehu_alemu from '../../images/Aboutus/American_Board/etagegnehu_alemu.webp'

const boardMembers = [
    {
        'id': 1,
        "name": 'Pastor Demeke and Wegayehu',
        'imageUrl': pastorAndWegayehu
    },
    {
        'id': 1,
        "name": 'Lenore Hutchinson, Board Chairman',
        'imageUrl': lenor_hut
    },
    {
        'id': 1,
        "name": 'John Lehman, CrossRoads Ministry',
        'imageUrl': john_lehman
    },
    {
        'id': 1,
        "name": 'Karen Burrows, Treasurer',
        'imageUrl': karen_burrows
    },
    {
        'id': 1,
        "name": 'Lindsey Teske, Webmaster',
        'imageUrl': Lindsey_Teske
    },
    {
        'id': 1,
        "name": 'Etagengehu Alemu, Vision Care',
        'imageUrl': etagegnehu_alemu
    }
]

const About2 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="about-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12  grid col-12">
                        <div className="video-area">
                            <img src={abimg} alt="" />
                            <div className="entry-media video-holder video-btn2">
                                <VideoModal/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 grid  col-md-12 col-12">
                        <div className="about-text">
                            <div className="section-title">
                                <div className="thumb-text">
                                    <span>ABOUT US</span>
                                </div>
                                <h2>SILOAM is <span>Nonprofit</span> Organization For Help on <span style={{color: "red"}}>Children</span> , Health and <span> Clean Water!</span> </h2>
                            </div>
                            <p>It is a long established fact that a reader will be distracted by thethe readable content off a page when looking at its layout point using Lorem Ipsum is that it has.</p>
                            <div className="ab-icon-area">
                                <div className="about-icon-wrap">
                                    <div className="about-icon-item">
                                        <div className="ab-icon">
                                            <img draggable="false" src={abimg2} alt="" />
                                        </div>
                                        <div className="ab-text">
                                            <h2><Link onClick={ClickHandler} to="/case-single">Save  Children.</Link></h2>
                                        </div>
                                    </div>
                                    <div className="about-icon-item">
                                        <div className="ab-icon ab-icon2">
                                            <img draggable="false" src={abimg3} alt="" />
                                        </div>
                                        <div className="ab-text">
                                            <h2><Link onClick={ClickHandler} to="/case-single">Fresh And  Clean Water.</Link></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{margin: '50px auto'}}>
                        <h3> Siloam Ethiopia Clinic Workers </h3>
                        <img src={groupPic} alt="" />
                    </div>
                    <div style={{margin: '50px auto'}}>
                        <h3 style={{fontSize: '80px', textAlign: 'center', margin: '25px auto'}}> Siloam Board of Directors </h3>
                        <div style={{display: 'flex', flexWrap: 'wrap', alighItems: 'center', justifyContent: 'space-around'}}>
                            {
                                boardMembers.map((member) => {
                                    return (
                                        <div style={{}}>
                                            <img src={member.imageUrl} alt="" style={{width: '400px', height: '400px', objectFit: 'cover'}} />
                                            <h6>{member.name}</h6>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About2;